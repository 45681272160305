export const TextSizes = {
  xSmall: "0.75rem",
  small: "1rem",
  medium: "1.5rem",
  large: "2rem",
  xLarge: "3rem",
  xxLarge: "4rem",
};

export const TextReset = `
  font-size: ${TextSizes.small};
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
